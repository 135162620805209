import HormonalHistoryTemplate from '@/modules/questionnaire/components/steps/common/medical-background/hormonal-history/HormonalHistoryTemplate';

import { BOOLEAN } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/MedicalBackground/HormonalHistory/HormonalHistoryTemplate',
  component: HormonalHistoryTemplate
};

const createStory = props => () => ({
  components: { HormonalHistoryTemplate },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template:
    '<div :style="$options.wrapperStyles"><hormonal-history-template v-bind="$options.storyProps" /></div>'
});

export const Yes = createStory({
  takingHormoneSupplements: BOOLEAN.YES
});

export const No = createStory({
  takingHormoneSupplements: BOOLEAN.NO
});
